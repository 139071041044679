<template>
  <div>

    <loading :loading="isLoading"></loading>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>

            <div class='text-center' v-if="error">
              <b-iconstack font-scale="5" class="mb-5" >
                <b-icon stacked icon="shield-fill-x" variant="danger" scale="1"></b-icon>
              </b-iconstack>

              <h4>{{ $t('GENERAL.title_error') }}</h4>

              <p v-if="isTokenMissing" class="paragraph">{{ $t('AUTH.ERRORS.token_missing') }}</p>
              <p v-if="tokenAlreadyUsed" class="paragraph">{{ $t('AUTH.ERRORS.token_already_used') }}</p>

              <router-link :to="{ name: 'login' }" class="btn btn-primary mt-3">{{ $t('GENERAL.back_to_home') }}</router-link>
            </div>
            <!--begin::Signin-->
            <div class='login-form login-signin' v-else>
              <div class='text-center'>
                <div class="img logo purple"></div>
              </div>

              <p class="paragraph">
                {{ $t('AUTH.activate_account_description') }}
              </p>

              <!--begin::Form-->
              <b-form class='form' @submit.stop.prevent='onSubmit'>

                <!-- <div
                  role='alert'
                  v-bind:class='{ show: errors.length }'
                  v-if="errors.length > 0"
                  class='alert fade alert-danger'
                >
                  <div class='alert-text' v-for='(error, i) in errors' :key='i'>
                    {{ error }}
                  </div>
                </div> -->

                <b-form-group
                  id='example-input-group-2'
                  :label="$t('FORMS.password')"
                  label-for='example-input-2'
                >
                  <b-form-input
                    class='form-control form-control-solid h-auto px-6'
                    type='password'
                    id='example-input-2'
                    name='example-input-2'
                    autocomplete="new-password"
                    v-model='$v.form.password.$model'
                    :state="validateState('password')"
                    aria-describedby='input-2-live-feedback'
                  ></b-form-input>

                  <b-form-invalid-feedback id='input-2-live-feedback'>
                    <span v-if="!$v.form.password.minLength">{{ $t('FORMS.length_requirement', { n: 8 }) }}</span><br>
                    <span v-if="!$v.form.password.strongPassword">{{ $t('FORMS.password_requirements') }}</span><br>
                    <span v-if="!$v.form.password.required">{{ $t('FORMS.required') }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id='input-repeat-group'
                  :label="$t('FORMS.confirm_password')"
                  label-for='input-repeat'
                >
                  <b-form-input
                    class='form-control form-control-solid h-auto px-6'
                    type='password'
                    id='input-repeat'
                    name='input-repeat'
                    autocomplete="repeat-password"
                    v-model='$v.form.repeatPassword.$model'
                    :state="validateState('repeatPassword')"
                    aria-describedby='input-repeat-live-feedback'
                  ></b-form-input>

                  <b-form-invalid-feedback id='input-repeat-live-feedback'>
                    <span v-if="!$v.form.repeatPassword.sameAsPassword">{{ $t('FORMS.same_password') }}</span><br>
                    <span v-if="!$v.form.repeatPassword.required">{{ $t('FORMS.required') }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div
                  class='d-flex flex-wrap justify-content-between align-items-center text-center'
                >

                  <button
                    ref='kt_login_signin_submit'
                    class='btn btn-primary font-weight-bold px-9 mt-3 font-size-3'
                    style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                  >
                    {{ $t('ACTIONS.send') }}
                  </button>
                </div>
                <!--end::Action-->
              </b-form>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import { minLength, sameAs, required } from 'vuelidate/lib/validators'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import ErrorsMixin from '@/services/mixins/errors.mixins'

export default {
  mixins: [ToastsMixin, validationMixin, ErrorsMixin],
  name: 'ActivateAccount',
  data () {
    return {
      error: '',
      form: {
        password: '',
        repeatPassword: ''
      }
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        strongPassword (password1) {
          return (
            /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{0,}/.test(password1)
          )
        }
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    ...mapActions({
      activateAccount: 'auth/activateAccount'
    }),
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm () {
      this.form = {
        email: null,
        password: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      const params = {
        token: this.token,
        password: this.$v.form.password.$model
      }

      this.activateAccount(params)
        .then(_ => {
          this.$router.push({ name: 'activateAccountSuccess' })
        })
        .catch(resp => {
          this.showErrorToast(this.mapAccountActivationError(resp.data.data))
        })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading'
    }),
    token () { return this.$route.query.token },
    isTokenMissing () { return this.error === 'missing_token' },
    tokenAlreadyUsed () { return this.error === 'token_already_used' }
  },
  mounted () {
    if (typeof this.token === 'undefined' || this.token === null || !this.token) {
      this.error = 'missing_token'
    }
  }
}
</script>
